import React, { useEffect, useMemo, useState } from 'react';
import { Bar, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, ComposedChart, Line } from 'recharts';
import { CheckboxesOptions } from '../../../../../../components/checkbox-select';
import { AggregateVectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../../../types';
import { ChartDataItem, ChartDataValues } from '../../types';
import { lineChartParameters, sortedChartsParameters } from '../../utils/helpers';
import { EmptyState } from './empty-state';
import './index.scss';
import { LoadingState } from './loading-state';
import { CustomTooltip } from './tooltip';

interface PropsInterface {
  data: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
  isLoading: boolean;
  aggregatedParameters: CheckboxesOptions<AggregateVectorParameterTypeInternal>[];
}

const strokeLineColor = '#9A9BA2';

export const AnalysisChart: React.FC<PropsInterface> = ({
  data,
  activeMeasurementRange,
  isLoading,
  aggregatedParameters,
}) => {
  const [chartData, setChartData] = useState<ChartDataItem[]>([]);

  const maxChartDataValue = useMemo(() => {
    const allValues = data.reduce<number[]>(
      (all, chartDataItem) => [...all, ...chartDataItem.values.map((valueObject) => valueObject.value)],
      [],
    );
    return Math.max(...allValues, 0);
  }, [data]);

  useEffect(() => {
    const chartData = data.map((el) => {
      const values = el.values.sort(
        (a: ChartDataValues, b: ChartDataValues) =>
          sortedChartsParameters.findIndex((f) => f === a.type) - sortedChartsParameters.findIndex((f) => f === b.type),
      );
      const dateKeyValue = values.reduce((prev, curr) => ({ ...prev, [`${curr.type}_${curr.index}`]: curr.value }), {});

      return {
        tooltipTime: el.time,
        time: el.tooltipTime,
        ...dateKeyValue,
        values,
      };
    });

    setChartData(chartData);
  }, [data, maxChartDataValue, activeMeasurementRange]);

  const xAxisInterval = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return 3;
      case MeasurementRange.MonthInDays:
        return 3;
      case MeasurementRange.WeekInDays:
      case MeasurementRange.YearInMonths:
        return 'preserveStartEnd';
      case MeasurementRange.TenYearsInYears:
        return 'preserveStartEnd';
      default:
        return 1;
    }
  }, [activeMeasurementRange]);

  const onlyPriceSelected = useMemo(
    () =>
      aggregatedParameters.filter((x) => x.checked).length === 1 &&
      !!aggregatedParameters.find((x) => x.value === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice)
        ?.checked,
    [aggregatedParameters],
  );

  const pricesShouldBeVisible = useMemo(
    () =>
      !!chartData.find((x) =>
        x.values.find((y) => y.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice),
      ),
    [chartData],
  );

  const missingData = useMemo(() => {
    return chartData.any((x) => x.values.length === 0);
  }, [chartData]);

  if (isLoading) return <LoadingState />;
  if (missingData) return <EmptyState missingData />;

  return (
    <>
      <div className="chart">
        <ResponsiveContainer width="100%" height={210}>
          <ComposedChart data={chartData} margin={{ top: 8, right: 0, left: -20, bottom: 0 }} stackOffset="sign">
            <CartesianGrid vertical={false} stroke={strokeLineColor} strokeOpacity={0.2} />
            <Tooltip content={<CustomTooltip />} />
            <XAxis
              tickMargin={8}
              dataKey="time"
              axisLine={false}
              interval={xAxisInterval}
              tickLine={{ stroke: 'transparent' }}
            />
            <YAxis
              axisLine={{ strokeWidth: '0' }}
              tickCount={6}
              tickMargin={6}
              tickLine={{ stroke: strokeLineColor, strokeWidth: '0.5px', strokeOpacity: '0.2' }}
              yAxisId="left"
            />
            {pricesShouldBeVisible && (
              <YAxis
                axisLine={{ strokeWidth: '0' }}
                tickCount={6}
                tickMargin={6}
                tickLine={{ stroke: strokeLineColor, strokeWidth: '0.5px', strokeOpacity: '0.2' }}
                yAxisId="right"
                orientation="right"
              />
            )}
            {chartData.length
              ? chartData[0].values.map((item, index) => {
                  return !lineChartParameters.includes(item.type) || onlyPriceSelected ? (
                    <Bar
                      radius={
                        index === chartData[0].values.filter((x) => !lineChartParameters.includes(x.type)).length - 1
                          ? [2, 2, 0, 0]
                          : undefined
                      }
                      key={`${item.type}_${item.index}`}
                      dataKey={`${item.type}_${item.index}`}
                      fill={!onlyPriceSelected ? item.color : '#FF4016'}
                      stackId="energy"
                      yAxisId="left"
                    />
                  ) : (
                    <Line
                      dot={false}
                      key={`${item.type}_${item.index}`}
                      type="monotone"
                      dataKey={`${item.type}_${item.index}`}
                      stroke={item.color}
                      strokeWidth={item.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice ? 2 : 1}
                      yAxisId={
                        item.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice ? 'right' : 'left'
                      }
                      strokeDasharray={
                        item.type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice ? '0' : '5 5'
                      }
                    />
                  );
                })
              : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
