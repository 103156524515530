import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTimePicker, IconEdit, Input } from '../..';
import './index.scss';

interface ComponentProps {
  fromName?: string;
  toName?: string;
  fromError?: string;
  toError?: string;
}

export const FromToInputForm: React.FC<ComponentProps> = ({ fromName = 'from', toName = 'to', fromError, toError }) => {
  const { t } = useTranslation('channel-details');
  const { control } = useFormContext();
  const [fromPicker, setFromPicker] = useState<boolean>(false);
  const [toPicker, setToPicker] = useState<boolean>(false);

  return (
    <div className="from-to-input-form">
      <Controller
        name={fromName}
        control={control}
        render={({ field }) => (
          <>
            <Input
              defaultValue={field.value}
              value={field.value}
              required
              readOnly
              onClickInput={() => setFromPicker(true)}
              endIcon={<IconEdit />}
              label={t('from')}
              {...(fromError ? { errorMessage: fromError } : {})}
            />
            <DialogTimePicker
              header={t('from')}
              open={fromPicker}
              setOpen={setFromPicker}
              onSave={(value) => field.onChange(value)}
              time={field.value}
            />
          </>
        )}
      />
      <Controller
        name={toName}
        control={control}
        render={({ field }) => (
          <>
            <Input
              defaultValue={field.value}
              value={field.value}
              required
              readOnly
              onClickInput={() => setToPicker(true)}
              endIcon={<IconEdit />}
              label={t('to')}
              {...(toError ? { errorMessage: toError } : {})}
            />
            <DialogTimePicker
              header={t('to')}
              open={toPicker}
              setOpen={setToPicker}
              onSave={(value) => field.onChange(value)}
              time={field.value}
            />
          </>
        )}
      />
    </div>
  );
};
