import React from 'react';
import { SupportedDevice } from 'lavva.webbluetooth';
import { useHorizontalScroll } from '../../hooks/use-scroll';
import { ProductListItem } from '../product-list-item';
import './index.scss';

interface ComponentProps {
  channelsPerSelectedType: SupportedDevice[];
}

const ProductList: React.FC<ComponentProps> = ({ channelsPerSelectedType }) => {
  const { scrollableRef } = useHorizontalScroll();

  return (
    <div className="product-list" ref={scrollableRef}>
      {channelsPerSelectedType.map((device, index) => (
        <ProductListItem key={index} device={device} type="product" />
      ))}
    </div>
  );
};

export default ProductList;
