import { useTranslation } from 'react-i18next';
import { useSetPartialControlMode } from '../../../../../api/modules/gate/gate.hooks';
import { InitiateButtonRectangle } from '../../../../../components';
import { useChannelsState } from '../../../../../hooks';
import { ChannelGateInterface, ChannelGateStateInterface, ChannelInterface } from '../../../../../types';
import { useGateSupportedFeatures } from '../../hooks/use-gate-supported';

interface ComponentProps {
  channel: ChannelInterface;
}

export const PartialMode: React.FC<ComponentProps> = ({ channel }) => {
  const { t } = useTranslation('channel-details');
  const { mutate } = useSetPartialControlMode();
  const { partialControlModeSupported } = useGateSupportedFeatures(channel);
  const { channelState } = useChannelsState();
  const state = channelState[channel.id] as ChannelGateStateInterface | undefined;

  const handleSetPartialMode = () => {
    mutate({
      channelId: channel.id,
      deviceId: channel.deviceId,
      value: !state?.partialControlModeStatus,
    });
  };

  if (!(channel.data as ChannelGateInterface).isPartialControlModeVisible || !partialControlModeSupported) return null;

  return (
    <InitiateButtonRectangle
      isOn={!!state?.partialControlModeStatus}
      onClickControl={handleSetPartialMode}
      className="m-t-16"
    >
      {t('partialMode')}
    </InitiateButtonRectangle>
  );
};
