import React from 'react';
import { useHistory } from 'react-router-dom';
import { SupportedDevice } from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { ROUTES } from '../../../../../routes';
import './index.scss';

interface ComponentProps {
  device: SupportedDevice;
  type: 'product' | 'more';
}

export const ProductListItem: React.FC<ComponentProps> = ({ device, type }) => {
  const history = useHistory();

  const handleRedirect = () => history.push(ROUTES.StoreDeviceDetails(device.ModelGuid));

  return (
    <div className={`product-list-item ${type}`} onClick={handleRedirect}>
      <div className="image">
        {device.Pictures.length && <img src={device.Pictures[0]} alt="product image" width="100%" height="100%" />}
      </div>
      <p className="name">{device.Name}</p>
      <p className="model-name">{device.ModelName}</p>
    </div>
  );
};
