import React, { ChangeEvent, useState } from 'react';
import { ControlWrapper, LightSlider, OnOffControl } from '../../../components';
import { LightToggleValueInternal } from '../../../data-access/gql-types/graphql';
import { useLightAction } from '../../../hooks';
import { ChannelInterface } from '../../../types';
import { ActionTimesActivations } from '../components/action-times-activation';

interface PropsInterface {
  channels: ChannelInterface[];
}
const LightAction: React.FC<PropsInterface> = ({ channels }) => {
  const [brightness, setBrightness] = useState<number>(100);
  const { handleControlsOn, handleSetBrightness } = useLightAction(channels);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBrightness(Number(event.target.value));
  };

  const handleBrightness = (event: React.PointerEvent<HTMLInputElement>) => {
    const brightness = Number((event.target as HTMLInputElement).value);
    handleSetBrightness(brightness);
  };

  return (
    <>
      <ControlWrapper>
        <OnOffControl
          actionOn={() => handleControlsOn(LightToggleValueInternal.On)}
          actionOff={() => handleControlsOn(LightToggleValueInternal.Off)}
        />
      </ControlWrapper>
      <div className="m-t-24 m-b-48 p-l-24 p-r-24">
        <LightSlider value={brightness} onChange={handleChange} onPointerUp={handleBrightness} />
      </div>
      <ActionTimesActivations channels={channels} />
    </>
  );
};

export default LightAction;
