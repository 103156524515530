import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconSchedule } from '../../../../../../components/icons';
import { AggregateVectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { MeasurementRange } from '../../../../../../types';
import { ChartDataItem, CostEnum } from '../../types';
import { summaryExludedParameters } from '../../utils/helpers';
import { calculateSummary, getSummaryParameters } from '../../utils/summary';
import './index.scss';

interface PropsInterface {
  summaryData: ChartDataItem[];
  activeMeasurementRange: MeasurementRange;
}

export const Summary: React.FC<PropsInterface> = ({ summaryData, activeMeasurementRange }) => {
  const { t } = useTranslation('channel-details');
  const [energyStats, setEnergyStats] = useState({});

  useEffect(() => {
    let stats = {};

    summaryData.forEach((entry) => {
      entry.values.forEach((val) => {
        const type = val.type;

        if (summaryExludedParameters.includes(type)) return;
        let value = val.value;

        if (type === AggregateVectorParameterTypeInternal.DynamicEnergyMarketPrice) {
          const activeEnergy = entry.values.find(
            (x) => x.type === AggregateVectorParameterTypeInternal.ForwardActiveEnergy,
          );

          if (activeEnergy !== undefined && !activeEnergy.empty) {
            value = parseFloat((val.value * activeEnergy.value).toFixed(2));
            stats = calculateSummary(stats, val, value, CostEnum.Cost);
          } else {
            const predictionEnergy = entry.values.find(
              (x) => x.type === AggregateVectorParameterTypeInternal.PredictionForwardActiveEnergy,
            );
            if (predictionEnergy !== undefined) {
              value = parseFloat((val.value * predictionEnergy.value).toFixed(2));
              stats = calculateSummary(stats, val, value, CostEnum.PredictedCost);
            }
          }
        } else {
          stats = calculateSummary(stats, val, value, type);
        }
      });
    });

    Object.keys(stats).forEach((phase) => {
      Object.keys(stats[phase]).forEach((type) => {
        const average = stats[phase][type].count ? stats[phase][type].sum / stats[phase][type].count : 0;
        stats[phase][type].average = average;
      });
    });

    setEnergyStats(stats);
  }, [summaryData]);

  const summarySectionName = useMemo(() => {
    switch (activeMeasurementRange) {
      case MeasurementRange.DayInHours:
        return t('summary.daily');
      case MeasurementRange.WeekInDays:
        return t('summary.weekly');
      case MeasurementRange.MonthInDays:
        return t('summary.monthly');
      case MeasurementRange.YearInMonths:
        return t('summary.yearly');
      case MeasurementRange.TenYearsInYears:
        return t('summary.tenYears');
      default:
        return '';
    }
  }, [activeMeasurementRange]);

  const renderSummarySectionRow = (label: string, value: string) => (
    <div className="summary__section-row">
      <span>{label}</span>
      <span className="summary__section-value">{value}</span>
    </div>
  );

  return (
    <div className="summary max-width-desktop">
      {Object.keys(energyStats).length ? (
        <div className="summary__caption">
          <IconSchedule />
          <span className="summary__caption-text">{summarySectionName}</span>
        </div>
      ) : null}
      {Object.keys(energyStats).map((phase) =>
        Object.keys(energyStats[phase]).map((type) => {
          const parameters = getSummaryParameters(type);

          return (
            <div key={type} className="summary__section summary__section--border">
              {(phase === '0' && Object.keys(energyStats[phase]).length !== 1) || ['1', '2', '3'].includes(phase) ? (
                <div className="summary__section-label">
                  {phase === '0' ? t(`energyMeasurements.types.summary.${type}`) : `${t('phase')} ${phase}`}
                  <div className="label-color" style={{ backgroundColor: energyStats[phase][type].color }} />
                </div>
              ) : null}
              {renderSummarySectionRow(
                t('summary.sum'),
                `${energyStats[phase][type].sum.toFixed(parameters.fixed)} ${parameters.unit}`,
              )}
              {renderSummarySectionRow(
                t('summary.average'),
                `${energyStats[phase][type].average.toFixed(parameters.fixed)} ${parameters.unit}`,
              )}
              {renderSummarySectionRow(
                t('summary.max'),
                `${
                  energyStats[phase][type].max !== null ? energyStats[phase][type].max.toFixed(parameters.fixed) : '-'
                } ${parameters.unit}`,
              )}
            </div>
          );
        }),
      )}
    </div>
  );
};
