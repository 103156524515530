import React, { useMemo } from 'react';
import {
  ChannelTypeInternal,
  DeviceConnectionState,
  LightToggleValueInternal,
  SwitchStateInternal,
} from '../../../data-access/gql-types/graphql';
import { useChannelsState } from '../../../hooks';
import { coverIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/cover';
import { gateIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/gate';
import { lightIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/light';
import { meterIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/meter';
import { optimizerIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/optimizer';
import { switchIcons } from '../../../modules/channel-configuration/components/icon-edit/icons/switch';
import {
  AvailableIcon,
  ChannelCoverStateInterface,
  ChannelInterface,
  ChannelLightStateInterface,
  ChannelSwitchStateInterface,
} from '../../../types';
import { ChannelGateInterface, ChannelGateStateInterface } from '../../../types/channel/gate';
import { IconMissing } from '../../icons';

interface PropsInterface {
  channel: ChannelInterface;
  statusOn?: boolean;
}

export const ChannelIcon: React.FC<PropsInterface> = ({ channel, statusOn }) => {
  const { channelState } = useChannelsState();

  const findIcon = (availableIcons: AvailableIcon[]): JSX.Element => {
    const icon = availableIcons.find((x) => x.iconName === channel.iconName || x.oldIconName === channel.iconName);
    if (icon) return icon.component;
    return availableIcons[0].component;
  };

  const icon = useMemo(() => {
    switch (channel.data.type) {
      case ChannelTypeInternal.Switch: {
        return findIcon(
          switchIcons(
            statusOn || (channelState[channel.id] as ChannelSwitchStateInterface)?.lavvaState === SwitchStateInternal.On,
          ),
        );
      }
      case ChannelTypeInternal.Light:
        return findIcon(
          lightIcons(
            statusOn ||
              (channelState[channel.id] as ChannelLightStateInterface)?.toggleValue === LightToggleValueInternal.On,
          ),
        );
      case ChannelTypeInternal.Blind:
        return findIcon(coverIcons((channelState[channel.id] as ChannelCoverStateInterface)?.lavvaStatePosition || 0));
      case ChannelTypeInternal.Gate:
        return findIcon(
          gateIcons(
            (channel.data as ChannelGateInterface)?.gateKind,
            (channelState[channel.id] as ChannelGateStateInterface)?.position || 0,
          ),
        );
      case ChannelTypeInternal.Meter:
        return findIcon(
          meterIcons(statusOn || channelState[channel.id]?.deviceConnectionState !== DeviceConnectionState.Disconnected),
        );
      case ChannelTypeInternal.Optimizer:
        return findIcon(
          optimizerIcons(
            statusOn || channelState[channel.id]?.deviceConnectionState !== DeviceConnectionState.Disconnected,
          ),
        );
      default:
        return <IconMissing />;
    }
  }, [
    channel.data.type,
    (channel.data as ChannelGateInterface)?.gateKind,
    (channelState[channel.id] as ChannelSwitchStateInterface)?.lavvaState,
    (channelState[channel.id] as ChannelLightStateInterface)?.toggleValue,
    (channelState[channel.id] as ChannelCoverStateInterface)?.lavvaStatePosition,
    (channelState[channel.id] as ChannelGateStateInterface)?.position,
    channelState[channel.id]?.deviceConnectionState,
  ]);

  return <>{icon}</>;
};
