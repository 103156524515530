import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Api } from 'lavva.exalushome';
import { NetworkConfigurationService } from 'lavva.exalushome.network';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { INetworkService } from 'lavva.exalushome/build/js/INetworkService';
import { useBackdropContext } from '../../../../../hooks';
import { toastSuccess } from '../../../../../utils/toast';
import { useHandleDataFrameErrors } from '../../../hooks';
import { isIpValid } from '../../../utils';
import { IpAddress } from '../types';

export const useStaticSettings = () => {
  const history = useHistory();
  const { t } = useTranslation('installation');
  const [ipAddress, setIpAddress] = useState<IpAddress>({ first: '', second: '', third: '', fourth: '' });
  const [subnetMask, setSubnetMask] = useState<IpAddress>({ first: '', second: '', third: '', fourth: '' });
  const [gateway, setGateway] = useState<IpAddress>({ first: '', second: '', third: '', fourth: '' });
  const [broadcast, setBroadcast] = useState<IpAddress>({ first: '', second: '', third: '', fourth: '' });
  const { handleError } = useHandleDataFrameErrors();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();

  const handleSubmit = useCallback(async () => {
    turnOnBackdrop();
    const IpAddress = [ipAddress.first, ipAddress.second, ipAddress.third, ipAddress.fourth].join('.');
    const SubnetMask = [subnetMask.first, subnetMask.second, subnetMask.third, subnetMask.fourth].join('.');
    const DefaultGateway = [gateway.first, gateway.second, gateway.third, gateway.fourth].join('.');
    const Broadcast = [broadcast.first, broadcast.second, broadcast.third, broadcast.fourth].join('.');

    const networkService = Api.Get<INetworkService>(NetworkConfigurationService.ServiceName);
    const result = await networkService.SetStaticIpConfigurationAsync({
      IpAddress,
      SubnetMask,
      DefaultGateway,
      Broadcast,
    });

    if (result === Status.OK) {
      toastSuccess({ content: t('exalusNetwork.staticSettingsSaved') });
      history.goBack();
    } else {
      handleError(result);
    }

    turnOffBackdrop();
  }, [ipAddress, subnetMask, gateway, broadcast]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>, property: string) => {
    if (event.target.value.length <= 3) {
      switch (property) {
        case 'ip': {
          setIpAddress({ ...ipAddress, [event.target.name]: event.target.value });
          break;
        }
        case 'mask': {
          setSubnetMask({ ...subnetMask, [event.target.name]: event.target.value });
          break;
        }
        case 'gateway': {
          setGateway({ ...gateway, [event.target.name]: event.target.value });
          break;
        }
        case 'broadcast': {
          setBroadcast({ ...broadcast, [event.target.name]: event.target.value });
          break;
        }
      }
    }
  };

  const submitDisabled = useMemo(() => {
    return !isIpValid(ipAddress) || !isIpValid(subnetMask) || !isIpValid(gateway) || !isIpValid(broadcast);
  }, [ipAddress, subnetMask, gateway, broadcast]);

  return {
    handleSubmit,
    handleChange,
    ipAddress,
    subnetMask,
    gateway,
    broadcast,
    submitDisabled,
  };
};
