import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeviceType, IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { uniq } from 'lodash';
import { useExalusDevices } from '../../../hooks';
import { DeviceListHookParams, GroupedDevice, HookDevices } from '../types';

export const useDevices = ({
  hideAll = false,
  updateMode = false,
  updatesAvailability = [],
}: DeviceListHookParams): HookDevices => {
  const { t: tc } = useTranslation('common');
  const { data: devices, isFetched } = useExalusDevices();

  const sortedDeviceTypes = [
    DeviceType[DeviceType.Driver],
    DeviceType[DeviceType.Receiver],
    DeviceType[DeviceType.Sensor],
    DeviceType[DeviceType.Controller],
    DeviceType[DeviceType.Remote],
    DeviceType[DeviceType.Scene],
    DeviceType[DeviceType.Multipurpose],
    DeviceType[DeviceType.Unknown],
  ];

  const deviceList = useMemo(() => {
    return devices?.filter((x) =>
      updateMode ? updatesAvailability.find((upt) => upt.UpdateResourceGuid === x.Guid) : true,
    );
  }, [devices, updateMode, updatesAvailability.length]);

  const typeList = useMemo(() => {
    return deviceList?.filter((x) =>
      updateMode ? updatesAvailability.find((upt) => upt.UpdateResourceGuid === x.Guid) : true,
    ).length
      ? uniq(deviceList?.map((device) => device && DeviceType[device.DeviceType]))
      : [];
  }, [deviceList, updateMode, updatesAvailability.length]);

  const groupedDevices: GroupedDevice[] = useMemo(() => {
    const groups = typeList
      .sort(
        (a: string, b: string) =>
          sortedDeviceTypes.findIndex((f) => f === a) - sortedDeviceTypes.findIndex((f) => f === b),
      )
      .map((type: string) => ({
        label: tc(`typesPlural.exalus.${type}`),
        devices: deviceList?.filter((device: IDevice) => DeviceType[device.DeviceType] === type) || [],
      }));

    return [...(!hideAll ? [{ label: tc('all'), devices: [] }] : []), ...groups];
  }, [typeList, deviceList, hideAll]);

  return {
    groupedDevices: groupedDevices || [],
    devices: deviceList || [],
    isFetched: isFetched && !!devices,
  };
};
