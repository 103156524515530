export enum ChannelIconTypeEnum {
  ICON_SUN = 'ICON_SUN',
  ICON_BULB = 'ICON_BULB',
  ICON_SMALL_BULB = 'ICON_SMALL_BULB',
  ICON_LIGHT_SWITCH = 'ICON_LIGHT_SWITCH',
  ICON_PADLOCK = 'ICON_PADLOCK',
  ICON_BULB_GU10 = 'ICON_BULB_GU10',
  ICON_FLUORESCENT_LAMP = 'ICON_FLUORESCENT_LAMP',
  ICON_CEILING_LAMP = 'ICON_CEILING_LAMP',
  ICON_FLOOR_LAMP = 'ICON_FLOOR_LAMP',
  ICON_DESK_LAMP = 'ICON_DESK_LAMP',
  ICON_BEDSIDE_LAMP = 'ICON_BEDSIDE_LAMP',
  ICON_PLAFON_LAMP = 'ICON_PLAFON_LAMP',
  ICON_WALL_SCONCE = 'ICON_WALL_SCONCE',
  ICON_RGB_CIRCLES = 'ICON_RGB_CIRCLES',
  ICON_WOODEN_BLIND = 'ICON_WOODEN_BLIND',
  ICON_ROLLER_BLIND = 'ICON_ROLLER_BLIND',
  ICON_WOODEN_GATE = 'ICON_WOODEN_GATE',
  ICON_HORIZONTAL_DOUBLE_GATE = 'ICON_HORIZONTAL_DOUBLE_GATE',
  ICON_BARRIER = 'ICON_BARRIER',
  ICON_CURTAIN_BLIND = 'ICON_CURTAIN_BLIND',
  ICON_HORIZONTAL_GATE = 'ICON_HORIZONTAL_GATE',
  ICON_HANDLE = 'ICON_HANDLE',
  ICON_DOOR = 'ICON_DOOR',
  ICON_THERMOMETER = 'ICON_THERMOMETER',
  ICON_LEAK = 'ICON_LEAK',
  ICON_HUMAN_MOVEMENT = 'ICON_HUMAN_MOVEMENT',
  ICON_HAND_MOVEMENT = 'ICON_HAND_MOVEMENT',
  ICON_BIG_DROP = 'ICON_BIG_DROP',
  ICON_THREE_DROPS = 'ICON_THREE_DROPS',
  ICON_WIND_FAN = 'ICON_WIND_FAN',
  ICON_BORDER_FAN = 'ICON_BORDER_FAN',
  ICON_ONE_ARROW_PRESSURE = 'ICON_ONE_ARROW_PRESSURE',
  ICON_BINARY_STATE = 'ICON_BINARY_STATE',
  ICON_SQUARE_METER = 'ICON_SQUARE_METER',
  ICON_CIRCLE_METER = 'ICON_CIRCLE_METER',
  ICON_THUNDER = 'ICON_THUNDER',
  ICON_SIGNAL_STATE = 'ICON_SIGNAL_STATE',
  ICON_THREE_ARROWS_PRESSURE = 'ICON_THREE_ARROWS_PRESSURE',
  ICON_SMALL_CAMERA = 'ICON_SMALL_CAMERA',
  ICON_FACADE_BLIND = 'ICON_FACADE_BLIND',
  ICON_VERTICAL_GATE = 'ICON_VERTICAL_GATE',
  ICON_PHOTO_CAMERA = 'ICON_PHOTO_CAMERA',
  ICON_ENTRY_PHONE = 'ICON_ENTRY_PHONE',
  ICON_PLUG = 'ICON_PLUG',
  SWITCH = 'SWITCH',
  COVER = 'COVER',
  METER = 'METER',
  OPTIMIZER = 'OPTIMIZER',
  LIGHT = 'LIGHT',
  GATE = 'GATE',
  MISSING = 'MISSING',
}

export interface AvailableIcon {
  iconName: string;
  component: JSX.Element;
  oldIconName?: string;
}

export enum InstallationIconTypeEnum {
  INSTALLATION_0 = 'INSTALLATION_0',
  INSTALLATION_1 = 'INSTALLATION_1',
  INSTALLATION_2 = 'INSTALLATION_2',
  INSTALLATION_3 = 'INSTALLATION_3',
  INSTALLATION_4 = 'INSTALLATION_4',
  INSTALLATION_5 = 'INSTALLATION_5',
  INSTALLATION_6 = 'INSTALLATION_6',
  INSTALLATION_7 = 'INSTALLATION_7',
  INSTALLATION_8 = 'INSTALLATION_8',
  INSTALLATION_9 = 'INSTALLATION_9',
  INSTALLATION_10 = 'INSTALLATION_10',
  INSTALLATION_11 = 'INSTALLATION_11',
  INSTALLATION_12 = 'INSTALLATION_12',
  INSTALLATION_13 = 'INSTALLATION_13',
  INSTALLATION_14 = 'INSTALLATION_14',
}

export enum ActionAdvancedIconTypeEnum {
  DEFAULT = 'DEFAULT',
  OUTSIDE = 'OUTSIDE',
  HEART = 'HEART',
  BALL = 'BALL',
  SPORT = 'SPORT',
  NIGHT = 'NIGHT',
  BRIEFCASE = 'BRIEFCASE',
  DRINK = 'DRINK',
  TV = 'TV',
  DOCUMENT = 'DOCUMENT',
  BAG = 'BAG',
  SHOPPING = 'SHOPPING',
  LOCK = 'LOCK',
  CAR = 'CAR',
  CLOSET = 'CLOSET',
  MUSIC = 'MUSIC',
  INSIDE = 'INSIDE',
  GYM = 'GYM',
  COFFEE = 'COFFEE',
  APPLE = 'APPLE',
  TRAVEL = 'TRAVEL',
  TOWEL = 'TOWEL',
  ALARM = 'ALARM',
  PHONE = 'PHONE',
  PHOTO = 'PHOTO',
  LEAF = 'LEAF',
  CART = 'CART',
  RAIN = 'RAIN',
  CAT = 'CAT',
  DOG = 'DOG',
  BIRD = 'BIRD',
  PUZZLE = 'PUZZLE',
  GUITAR = 'GUITAR',
  MESSAGE = 'MESSAGE',
  SUN = 'SUN',
  PLAY = 'PLAY',
  MARGARITA = 'MARGARITA',
  CINEMA = 'CINEMA',
}

export enum GroupIconTypeEnum {
  BABY = 'BABY',
  BED = 'BED',
  CUTLERY = 'CUTLERY',
  GARAGE = 'GARAGE',
  HOOD = 'HOOD',
  LEAF_2 = 'LEAF_2',
  MODULE_DEVICE = 'MODULE_DEVICE',
  OVEN = 'OVEN',
  PLANT = 'PLANT',
  PLUG = 'PLUG',
  SHED = 'SHED',
  SHOWER = 'SHOWER',
  SOCKET = 'SOCKET',
  SOFA = 'SOFA',
  TABLE_SETTING = 'TABLE_SETTING',
  TREE = 'TREE',
  ARMCHAIR = 'ARMCHAIR',
  BABY_BED = 'BABY_BED',
  BARREL = 'BARREL',
  BATH = 'BATH',
  BLOCKS = 'BLOCKS',
  BOOK = 'BOOK',
  BOTTLE = 'BOTTLE',
  CAR_SMALL = 'CAR_SMALL',
  CHEST = 'CHEST',
  DESK = 'DESK',
  FRIDGE = 'FRIDGE',
  FURNACE = 'FURNACE',
  GRILL = 'GRILL',
  PROJECTOR = 'PROJECTOR',
  HAMMER = 'HAMMER',
  LAPTOP = 'LAPTOP',
  LAUNDRY = 'LAUNDRY',
  MELODY = 'MELODY',
  METER = 'METER',
  MIRROR = 'MIRROR',
  NOTEBOOK = 'NOTEBOOK',
  PUZZLE_2 = 'PUZZLE_2',
  SCREWDRIVER = 'SCREWDRIVER',
  SINK = 'SINK',
  TABLE_CHAIRS = 'TABLE_CHAIRS',
  TV_2 = 'TV_2',
  UMBRELLA = 'UMBRELLA',
  WARDROBE = 'WARDROBE',
  WASHING_MACHINE = 'WASHING_MACHINE',
  WRENCH_TOOL = 'WRENCH_TOOL',
}

export enum RgbSceneIconTypeEnum {
  DEFAULT = 'DEFAULT',
  OUTSIDE = 'OUTSIDE',
  BALL = 'BALL',
  SPORT = 'SPORT',
  NIGHT = 'NIGHT',
  BRIEFCASE = 'BRIEFCASE',
  DRINK = 'DRINK',
  TV = 'TV',
  DOCUMENT = 'DOCUMENT',
  BAG = 'BAG',
  SHOPPING = 'SHOPPING',
  LOCK = 'LOCK',
  CAR = 'CAR',
  CLOSET = 'CLOSET',
  MUSIC = 'MUSIC',
  INSIDE = 'INSIDE',
  GYM = 'GYM',
  COFFEE = 'COFFEE',
  APPLE = 'APPLE',
  TRAVEL = 'TRAVEL',
  TOWEL = 'TOWEL',
  ALARM = 'ALARM',
  PHONE = 'PHONE',
  PHOTO = 'PHOTO',
  LEAF = 'LEAF',
  CART = 'CART',
  RAIN = 'RAIN',
  CAT = 'CAT',
  DOG = 'DOG',
  BIRD = 'BIRD',
  PUZZLE = 'PUZZLE',
  GUITAR = 'GUITAR',
  MESSAGE = 'MESSAGE',
  SUN = 'SUN',
  PLAY = 'PLAY',
  MARGARITA = 'MARGARITA',
  CINEMA = 'CINEMA',
}
