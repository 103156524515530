import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalSite } from 'lavva.exalushome/build/js/Services/SupportedDevices/ISupportedDevicesService';
import { Header } from '../../../../../components';
import ArrowButton from '../../../../../components/arrow-button';

interface ComponentProps {
  buySources: ExternalSite[];
}

export const WhereBuy: React.FC<ComponentProps> = ({ buySources }) => {
  const { t } = useTranslation('store');

  if (!buySources.length) return null;

  return (
    <div className="buy-section">
      <hr className="m-t-24 m-b-24" />

      <Header title={t('buy')} />
      <div className="grid-list-24">
        {buySources.map((x, i) => (
          <ArrowButton
            key={x.Id}
            title={x.Name}
            dark
            small
            onClick={() => window.open(x.Link, '_blank')}
            {...(i < buySources.length - 1 ? { className: 'm-b-16' } : {})}
          />
        ))}
      </div>
    </div>
  );
};
