import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import {
  AcceptInvitationMutation,
  AcceptInvitationMutationVariables,
  AllInvitationsQueryVariables,
  IntroduceMutation,
  IntroduceMutationVariables,
  Invitation,
  Query,
  RejectInvitationMutation,
  RejectInvitationMutationVariables,
} from '../../data-access/gql-types/graphql';
import { INTRODUCE, INVITATION_ACCEPT } from '../../data-access/mutations/invitation';
import { INVITATION_REJECT } from '../../data-access/mutations/invitation';
import { ALL_INVITATIONS } from '../../data-access/queries/invitations';
import { MutationErrorType } from '../../types';
import * as storage from '../../utils/storage/lavva';
import { toastError } from '../../utils/toast';
import { useMutationErrors } from '../backend-errors/use-mutation-errors';
import { useInstallation } from '../installation';
import { useBackdropContext } from '../use-backdrop';

interface HookInterface {
  invitationAcceptById: (invitationId: string, notificationId: string, onSuccess: () => void) => void;
  invitationRejectById: (invitationId: string, notificationId: string, onSuccess: () => void) => void;
  allInvitationsLoading: boolean;
  allInvitations: Invitation[];
  refetchInvitations: () => Promise<ApolloQueryResult<Query>>;
}

export default function (): HookInterface {
  const { t: tc } = useTranslation('common');
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Invitation);
  const { skipLavvaFetch } = useInstallation();
  const [invitationIntroduce] = useMutation<IntroduceMutation, IntroduceMutationVariables>(INTRODUCE);
  const {
    data: allInvitations,
    loading: allInvitationsLoading,
    refetch,
  } = useQuery<Query, AllInvitationsQueryVariables>(ALL_INVITATIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      includeCreatedByMe: true,
      includeInvitedMe: true,
    },
    skip: skipLavvaFetch,
  });
  const [invitationAccept] = useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(
    INVITATION_ACCEPT,
  );

  const invitations = useMemo(() => allInvitations?.allInvitations || [], [allInvitations]);

  useEffect(() => {
    if (!storage.getItem('wasIntroduced')) {
      invitationIntroduce({
        onCompleted: (res) => {
          if (res.introduce) {
            storage.setItem('wasIntroduced', true);
          } else {
            toastError({ content: tc('errors.somethingWentWrong') });
            // TODO errors
          }
        },
      });
    }
  }, []);

  const invitationAcceptById = useCallback(
    (invitationId, notificationId, onSuccess) => {
      invitationAccept({
        variables: {
          input: {
            invitationId,
          },
        },
        onCompleted: (data) => {
          if (data.acceptInvitation.result?.ok) {
            onSuccess();
          } else {
            turnOffBackdrop();
            handleErrors(data.acceptInvitation.errors || []);
          }
        },
        onError: () => turnOffBackdrop(),
      });
    },
    [invitationAccept],
  );

  const [invitationReject] = useMutation<RejectInvitationMutation, RejectInvitationMutationVariables>(
    INVITATION_REJECT,
  );

  const invitationRejectById = useCallback(
    (invitationId, notificationId, onSuccess) => {
      turnOnBackdrop();
      invitationReject({
        variables: {
          input: {
            invitationId,
          },
        },
        onCompleted: (data) => {
          if (data.rejectInvitation.result?.ok) {
            onSuccess();
          } else {
            turnOffBackdrop();
            handleErrors(data.rejectInvitation.errors || []);
          }
        },
        onError: () => turnOffBackdrop(),
      });
    },
    [invitationReject],
  );

  const refetchInvitations = () => refetch();

  return {
    invitationAcceptById,
    invitationRejectById,
    allInvitations: invitations,
    allInvitationsLoading,
    refetchInvitations,
  };
}
