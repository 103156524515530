import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';
import omitDeep from 'omit-deep';
import { Header, NavHeader, Page, SubmitButton, Tabs } from '../../../../components';
import ArrowButton from '../../../../components/arrow-button';
import {
  ChannelTypeInternal,
  MeterTariffConfig,
  TariffKindInternal,
} from '../../../../data-access/gql-types/graphql';
import { useDevicesAndChannelsContext } from '../../../../hooks/devices-and-channels/provider';
import { ChannelMeterInterface } from '../../../../types';
import { timeToHour } from '../../../../utils/helpers';
import { pathnameWithParameters } from '../../../../utils/location';
import { TariffForm } from '../../../common/tariff';
import { TariffBody } from '../../../channel-details/optimizer/configuration/types';
import { getTariffOperatorEnum } from '../../../channel-details/optimizer/configuration/utils';
import { useMeterTariffConfiguration } from '../../hooks/use-meter-tariff-configuration';
import TariffPrices from '../tariff-prices';

interface ComponentProps {
  setTimeoutError: () => void;
}

const Tariff: React.FC<ComponentProps> = ({ setTimeoutError }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t } = useTranslation('configuration');
  const [configurationVisible, setConfigurationVisible] = useState<boolean>(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { channel } = useDevicesAndChannelsContext();
  const form = useForm<TariffBody>({
    defaultValues: {
      operator: '',
      tariffName: '',
      wholeWeekendCheaper: false,
      wholeHolidaysCheaper: false,
      ignoreSummerTimeChanges: false,
      hoursRanges: [],
    },
  });
  const values = form.watch();
  const { setMeterTariffData, handleSubmitTariff } = useMeterTariffConfiguration(form);

  useEffect(() => {
    if ((channel?.data as ChannelMeterInterface)?.tariff) {
      setMeterTariffData((channel?.data as ChannelMeterInterface).tariff);
    }
  }, [JSON.stringify((channel?.data as ChannelMeterInterface)?.tariff)]);

  useEffect(() => {
    const configuration = new URLSearchParams(search).get('tariff');
    setConfigurationVisible(!!configuration);
  }, [search]);

  const tariffKind = useMemo(
    () => (channel?.data as ChannelMeterInterface).tariff?.kind,
    [(channel?.data as ChannelMeterInterface).tariff],
  );

  const tabs = useMemo(
    () => [
      { label: t('tariffs') },
      ...(tariffKind &&
      ![
        TariffKindInternal.EonDynamic,
        TariffKindInternal.PgeDynamic,
        TariffKindInternal.EneaDynamic,
        TariffKindInternal.EnergaDynamic,
        TariffKindInternal.TauronDynamic,
      ].includes(tariffKind)
        ? [{ label: t('prices') }]
        : []),
    ],
    [t, tariffKind],
  );

  const tariffsDisabled = useMemo(() => {
    if (!values.operator || !values.tariffName) return true;

    const original = omitDeep(cloneDeep((channel?.data as ChannelMeterInterface).tariff), ['__typename']);

    const newData: MeterTariffConfig = {
      wholeWeekendCheaper: values.wholeWeekendCheaper,
      wholeHolidaysCheaper: values.wholeHolidaysCheaper,
      ignoreSummerTimeChanges: values.ignoreSummerTimeChanges,
      kind: getTariffOperatorEnum(values.operator, values.tariffName) as TariffKindInternal,
      hoursRanges: values.hoursRanges.map((x) => ({
        from: timeToHour(x.from),
        to: timeToHour(x.to),
        timeZoneKind: x.timeZoneKind,
        pricingTier: x.pricingTier,
      })),
    };

    return isEqual(original, newData);
  }, [(channel?.data as ChannelMeterInterface).tariff, values]);

  const openConfiguration = () => {
    history.push(pathnameWithParameters(pathname, search, { key: 'tariff', value: true }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const values = form.getValues();

    handleSubmitTariff(values, () => setTimeoutError());
  };

  return (
    <>
      {!configurationVisible ? (
        <ArrowButton title={t('tariffsConfiguration')} className="m-b-12" onClick={openConfiguration} small />
      ) : (
        <Page
          kind="above"
          header={
            <>
              <NavHeader />
              <Header title={t('tariffsConfiguration')} />
              <Tabs
                tabList={tabs}
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
                tabsType="pills"
                isDivider={false}
              />
            </>
          }
        >
          {activeTabIndex === 0 ? (
            <FormProvider {...form}>
              <form onSubmit={onSubmit}>
                <TariffForm type={ChannelTypeInternal.Meter} customTariff={false} />
                <SubmitButton disabled={tariffsDisabled} />
              </form>
            </FormProvider>
          ) : (
            <TariffPrices />
          )}
        </Page>
      )}
    </>
  );
};

export default Tariff;
