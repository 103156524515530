import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { EcoBar } from '../../../../../../components/channel/eco-bar';
import { WRONG_MEASUREMENT_VALUE } from '../../../../../../const';
import { OptimizerVectorParameterTypeInternal } from '../../../../../../data-access/gql-types/graphql';
import { measurementParametersOptimizer } from '../../../../measurements/utils';
import { Balance } from '../../../current/components/balance';
import { OptimizerCurrentDataMap } from '../../../current/types';
import './index.scss';

interface ComponentProps {
  optimizerData: Record<OptimizerVectorParameterTypeInternal, OptimizerCurrentDataMap[]> | undefined;
}

export const Preview: React.FC<ComponentProps> = ({ optimizerData }) => {
  const { t } = useTranslation('channel-details');

  const renderPhase = (nr: number) => {
    const scrValue = orderBy(optimizerData?.SCR_FILLING, 'index')[nr - 1].value;

    return (
      <div className="phase-preview">
        <div className="phase-title">{`${t('phase')} ${nr}`}</div>
        <div>{`${orderBy(optimizerData?.VOLTAGE, 'index')[nr - 1].value.toFixed(
          measurementParametersOptimizer.VOLTAGE.fixed,
        )} ${measurementParametersOptimizer.VOLTAGE.unit}`}</div>
        <div>{`${(orderBy(optimizerData?.ACTIVE_POWER, 'index')[nr - 1].value / 1000).toFixed(
          measurementParametersOptimizer.ACTIVE_POWER.fixed,
        )} ${measurementParametersOptimizer.ACTIVE_POWER.unit}`}</div>
        <div className="load m-t-4">
          <EcoBar
            value={scrValue !== WRONG_MEASUREMENT_VALUE ? Math.abs(scrValue) : 0}
            maxValue={100}
            disabled={scrValue === WRONG_MEASUREMENT_VALUE}
          />
          {`${
            scrValue !== WRONG_MEASUREMENT_VALUE
              ? Math.abs(scrValue).toFixed(measurementParametersOptimizer.SCR_FILLING.fixed)
              : 0
          } ${measurementParametersOptimizer.SCR_FILLING.unit}`}
        </div>
      </div>
    );
  };

  return optimizerData ? (
    <div className="optimizer-preview">
      <div className="phases m-b-8">
        {renderPhase(1)}
        {renderPhase(2)}
        {renderPhase(3)}
      </div>
      <Balance
        kind="only-power"
        selectedParameter={OptimizerVectorParameterTypeInternal.ActivePower}
        optimizerData={optimizerData}
      />
    </div>
  ) : null;
};
