import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';
import {
  LamelaManualCalibration,
  LamelaTiltCalibration,
} from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/FacadeBlindService/ZfBidiConfigParams';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { ROUTES } from '../../../../../../../routes';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

interface ComponentProps {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  movementService?: LamelaManualCalibration;
  tiltService?: LamelaTiltCalibration;
}

const ZfBidiCalibrationStep4_5: React.FC<ComponentProps> = ({ step, setStep, movementService, tiltService }) => {
  const { channel, history, t, turnOnBackdrop, turnOffBackdrop } = useCalibration();
  const { handleError } = useHandleDataFrameErrors();
  const { request, release } = useWakeLock();

  useEffect(() => {
    request();

    return () => {
      release();
    };
  }, []);

  useEffect(() => {
    if (!movementService && !tiltService) history.goBack();
  }, [movementService, tiltService]);

  const handleClick = useCallback(async () => {
    if (channel && tiltService) {
      turnOnBackdrop();

      if (step === 4) {
        const confirmResult = await tiltService.ConfirmFirstPositionAsync();

        if (confirmResult === Status.OK) {
          turnOffBackdrop();
          setStep(5);
          history.push(`${ROUTES.FacadeCalibration(channel.ChannelId)}?step=5`);
        } else handleError(confirmResult);
      } else {
        const confirmResult = await tiltService.ConfirmSecondPositionAsync();

        if (confirmResult === Status.OK) {
          const endResult = await tiltService.EndCalibrationAsync();

          if (endResult === Status.OK) {
            turnOffBackdrop();
            setStep(6);
            history.push(`${ROUTES.FacadeCalibration(channel.ChannelId)}?step=6`);
          } else handleError(endResult);
        } else {
          handleError(confirmResult);
        }
      }
    }
  }, [channel, step, tiltService]);

  const handleDown = useCallback(async () => {
    if (movementService) {
      turnOnBackdrop();
      const result = await movementService.MoveOneStepDownAsync();
      if (result !== Status.OK) handleError(result);
      turnOffBackdrop();
    }
  }, [movementService]);

  const handleUp = useCallback(async () => {
    if (movementService) {
      turnOnBackdrop();
      const result = await movementService.MoveOneStepUpAsync();
      if (result !== Status.OK) handleError(result);
      turnOffBackdrop();
    }
  }, [movementService]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      <p className="info m-b-24">
        {t(`exalus.params.ZfBidiParams.calibrationFlow.setLamels${step === 4 ? 'Close' : 'Open'}`)}
      </p>

      {channel && <ChannelBoxIcon channel={channel} className="facade-icon" />}
      <p className="info--sub m-b-24 tiny">
        {t(`exalus.params.ZfBidiParams.calibrationFlow.setLamelsOpenInfo_${step}`)}
      </p>

      <button className="button button--secondary m-t-24" onClick={handleUp}>
        {t('exalus.params.ZfBidiParams.calibrationFlow.moveOneStepUp')}
      </button>
      <button className="button button--secondary m-t-24" onClick={handleDown}>
        {t('exalus.params.ZfBidiParams.calibrationFlow.moveOneStepDown')}
      </button>

      <SubmitButton type="button" onClick={handleClick} tranparent>
        {t('exalus.params.ZfBidiParams.calibrationFlow.next')}
      </SubmitButton>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default ZfBidiCalibrationStep4_5;
