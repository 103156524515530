import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { ManualCalibration } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../../../../../routes';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useConfigurationService } from '../../../../device-details/hooks/use-configuration-service';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

interface ComponentProps {
  setStep: Dispatch<SetStateAction<number>>;
  setManualService: Dispatch<SetStateAction<ManualCalibration | undefined>>;
}

const Srp22CalibrationStep0: React.FC<ComponentProps> = ({ setStep, setManualService }) => {
  const { handleGetBlindParamsResponse } = useConfigurationService();
  const { channel, history, backdropOpen, t, turnOnBackdrop, turnOffBackdrop, getSrp22ConfigService } =
    useCalibration();
  usePreventBack(backdropOpen ? t('exalus.params.ZfBidiParams.calibrationFlow.noBackMessage') : '');

  const handleClick = useCallback(async () => {
    if (channel) {
      turnOnBackdrop();
      const configService = await getSrp22ConfigService();
      const result = await configService.ManualCalibration(channel?.GetDevice(), channel.Number);

      handleGetBlindParamsResponse<ManualCalibration>(result, () => {
        setManualService(result as ManualCalibration);
        setStep(1);
        turnOffBackdrop();
        history.push(`${ROUTES.Srp22Calibration(channel.ChannelId)}?step=1`);
      });
    }
  }, [channel, channel?.ChannelId]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="blind-icon" />}
      <p className="channel-name">{channel?.Name}</p>

      <p className="info m-t-48">{t('exalus.params.SrpParams.calibrationFlow.welcome')}</p>
      <p className="info--sub">{t('exalus.params.SrpParams.calibrationFlow.nextInfo')}</p>
      <SubmitButton type="button" onClick={handleClick} tranparent>
        {t('exalus.params.SrpParams.calibrationFlow.next')}
      </SubmitButton>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default Srp22CalibrationStep0;
