import React from 'react';
import CheckContentWrapper, { PageType } from '../../common/check-content-wrapper';
import { DashboardCarousel } from './carousel';

interface ComponentProps {
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  isListView: boolean;
}

export const DashboardBody: React.FC<ComponentProps> = ({ activeTabIndex, setActiveTabIndex, isListView }) => (
  <CheckContentWrapper page={PageType.Dashboard}>
    <DashboardCarousel activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} isListView={isListView} />
  </CheckContentWrapper>
);
