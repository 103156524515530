import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyTask, NotifyType } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { SupportedTaskTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { Header, InputSelect, NavHeader, Page, SubmitButton, TextArea } from '../../../../../../../components';
import { toastError } from '../../../../../../../utils/toast';
import { useExalusCreateActionContext } from '../../../context';
import { useNotifyTask } from '../../../hooks/use-notify-task';
import { useTaskEdit } from '../../../hooks/use-task-edit';
import { NotifyTaskParams } from '../../../types';
import { specialPhrasePattern } from '../../../utils/notify-helpers';
import { Tiles } from './tiles';
import './index.scss';

const NotifyView: React.FC = () => {
  const { t } = useTranslation('action');
  const { task, finalizeNotifyTaskSubmit } = useTaskEdit();
  const { supportedTaskTypes } = useExalusCreateActionContext();
  const [notifyType, setNotifyType] = useState<NotifyType>(NotifyType.AppNotification);
  const [message, setMessage] = useState<string>('');
  const { channelsAvailable, getMessageParsed } = useNotifyTask();

  useEffect(() => {
    if (task) {
      const message = (task.taskParams as NotifyTaskParams).message;
      const notifyType = (task.taskParams as NotifyTaskParams).notifyType;

      if (notifyType) setNotifyType(notifyType);
      if (message) setMessage(message);
    }
  }, [task]);

  const supportedOptions = useMemo(() => {
    return !supportedTaskTypes.length
      ? { app: true, email: false }
      : {
          app: !!supportedTaskTypes.find((x) => x === SupportedTaskTypes.NotifyTaskAppNotify),
          email: !!supportedTaskTypes.find((x) => x === SupportedTaskTypes.NotifyTaskEmailNotify),
        };
  }, [supportedTaskTypes]);

  const messageParsed = useMemo(() => getMessageParsed(message), [message]);

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = event.target.value;
    const specialPhrases = message.match(specialPhrasePattern) || [];
    const newSpecialPhrases: string[] = newMessage.match(specialPhrasePattern) || [];

    if (specialPhrases.every((phrase: string) => newSpecialPhrases.includes(phrase))) {
      setMessage(newMessage);
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Backspace') {
      const cursorPosition = event.currentTarget.selectionStart;
      const specialPhrases = message.match(specialPhrasePattern) || [];

      for (const phrase of specialPhrases) {
        const startIdx = message.indexOf(phrase);
        const endIdx = startIdx + phrase.length;

        if ((startIdx <= cursorPosition && cursorPosition <= endIdx) || cursorPosition === endIdx) {
          const updatedMessage = message.slice(0, startIdx) + message.slice(endIdx);
          setMessage(updatedMessage);

          event.preventDefault();
          return;
        }
      }
    }
  };

  const handleTileSelect = (val: string) =>
    setMessage((prev) => {
      const updatedMessage = prev.length ? `${prev} ${val}` : val;

      if (updatedMessage.length > 1000) {
        toastError({ content: t('action.create.tasks.limitCharacters') });
        return prev;
      }

      return updatedMessage;
    });

  const handleSubmit = () => {
    const task = new NotifyTask();
    task.Message = message;
    task.NotifyType = NotifyType.AppNotification;
    task.NotificationClients = [];

    finalizeNotifyTaskSubmit(task);
  };

  return (
    <Page
      isStickyHeader
      header={
        <>
          <NavHeader />
          <Header title={t('action.create.tasks.notify')} />
        </>
      }
    >
      <>
        <div className="grid-list-24">
          <InputSelect
            options={[
              {
                label: t('action.create.tasks.appNotification'),
                value: NotifyType.AppNotification,
                disabled: !supportedOptions.app,
              },
              {
                label: t('action.create.tasks.email'),
                value: NotifyType.Email,
                disabled: !supportedOptions.email,
              },
            ]}
            value={notifyType}
            onChange={setNotifyType}
            label={t('action.create.tasks.notifyType')}
          />
        </div>
        <TextArea
          defaultValue={message}
          value={message}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          label={t('action.create.tasks.editor')}
          placeholder={t('action.create.tasks.message')}
          rows={4}
          maxLength={1000}
        />
        {messageParsed && (
          <div className="parsed-message">
            <p>{t('action.create.tasks.message')}</p>
            <p>{messageParsed}</p>
          </div>
        )}
        <Tiles channelsAvailable={channelsAvailable} handleTileSelect={handleTileSelect} />
        <SubmitButton onClick={handleSubmit} />
      </>
    </Page>
  );
};

export default NotifyView;
