import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { ManualCalibration } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/Common/BlindConfigurations/BlindConfigParameters';
import { Status } from 'lavva.exalushome/build/js/DataFrame';
import { CustomBackdrop, Header, NavHeader, Page, SubmitButton } from '../../../../../../../components';
import { usePreventBack } from '../../../../../../../hooks/navigation-guard/use-navigation-guard';
import { ROUTES } from '../../../../../../../routes';
import { toastInfo } from '../../../../../../../utils/toast';
import { ChannelBoxIcon } from '../../../../../components/channel/box-icon';
import { useHandleDataFrameErrors } from '../../../../../hooks';
import { useCalibration } from '../../common/hooks/use-calibration';
import './index.scss';

interface ComponentProps {
  setStep: Dispatch<SetStateAction<number>>;
  manualService?: ManualCalibration;
}

const Srp22CalibrationStep1: React.FC<ComponentProps> = ({ setStep, manualService }) => {
  const { channel, history, backdropOpen, t, turnOnBackdrop, turnOffBackdrop } = useCalibration();
  const { handleError } = useHandleDataFrameErrors();
  usePreventBack(backdropOpen ? t('exalus.params.ZfBidiParams.calibrationFlow.noBackMessage') : '');

  useEffect(() => {
    if (!manualService) history.replace(ROUTES.Srp22Calibration(channel?.ChannelId));
  }, [manualService]);

  const handleClick = useCallback(() => {
    if (channel) {
      setStep(2);
      history.push(`${ROUTES.Srp22Calibration(channel.ChannelId)}?step=2`);
    }
  }, [channel]);

  const openBlind = useCallback(async () => {
    if (manualService) {
      turnOnBackdrop();
      const result = await manualService.StepAOpenBlindAsync();

      if (result === Status.OK) {
        toastInfo({ content: t('exalus.params.SrpParams.calibrationFlow.stepAExecuted') });
      } else {
        handleError(result);
      }

      turnOffBackdrop();
    }
  }, [channel]);

  return (
    <Page
      className="calibration-flow"
      isStickyHeader
      whiteContent
      header={
        <>
          <NavHeader />
          <Header title={t('exalus.calibration')} />
        </>
      }
    >
      {channel && <ChannelBoxIcon channel={channel} className="blind-icon" />}
      <p className="info m-t-40">{t('exalus.params.SrpParams.calibrationFlow.stepAInfo')}</p>
      <button className="button button--secondary m-t-24 m-b-48" onClick={openBlind}>
        {t('exalus.params.SrpParams.calibrationFlow.stepAAction')}
      </button>
      <p className="info--sub tiny m-b-24">{t('exalus.params.SrpParams.calibrationFlow.stepAConfirmation')}</p>
      <SubmitButton type="button" onClick={handleClick} tranparent>
        {t('exalus.params.SrpParams.calibrationFlow.next')}
      </SubmitButton>
      {!channel && <CustomBackdrop loading />}
    </Page>
  );
};

export default Srp22CalibrationStep1;
