import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChannelBox } from '../../../components';
import { GateModeInternal, VersionedFeatureType } from '../../../data-access/gql-types/graphql';
import { useDevicesAndChannels } from '../../../hooks';
import useVersionMap from '../../../hooks/api/version-map';
import { useDevicesAndChannelsContext } from '../../../hooks/devices-and-channels/provider';
import { useGateSupportedFeatures } from '../../channel-details/gate/hooks/use-gate-supported';
import { Calibration } from '../../device-settings/device-settings-form/sections';
import AlertConfig from '../components/alert-config';
import Info from '../components/info';
import SignalTime from '../components/signal-time';
import { useGateConfiguration } from '../hooks/use-gate-configuration';
import { InfoPageType } from '../types';

interface ComponentProps {
  setInfoPage: Dispatch<SetStateAction<InfoPageType>>;
}

const GateConfiguration: React.FC<ComponentProps> = ({ setInfoPage }) => {
  const { t } = useTranslation('configuration');
  const { channel } = useDevicesAndChannelsContext();
  const { device } = useDevicesAndChannels({ deviceId: channel?.deviceId });
  const { calibrationSupported } = useGateSupportedFeatures(channel);
  const { checkSupport, versionMapData } = useVersionMap();
  const { handleSaveSignalTime, gateMode, handleSaveAlert } = useGateConfiguration();

  const alertSupported = useMemo(() => {
    return checkSupport({
      feature: VersionedFeatureType.GateAlerts,
      supportKey: 'Gate',
      currentVersion: device?.payload.currentFirmwareVersion,
    });
  }, [versionMapData, device?.payload.currentFirmwareVersion]);

  if (!channel) return null;

  return (
    <>
      <Info title={t('channelTest')} onClick={() => setInfoPage(InfoPageType.CHANNEL)} />
      <ChannelBox channel={channel} isListItem />
      {gateMode === GateModeInternal.StepByStep && (
        <>
          <hr />
          <SignalTime channel={channel} handleSave={handleSaveSignalTime} />
        </>
      )}
      {alertSupported && (
        <>
          <hr />
          <AlertConfig channel={channel} handleSave={handleSaveAlert} />
        </>
      )}

      {calibrationSupported && (
        <>
          <hr />
          <Info title={t('calibration')} onClick={() => setInfoPage(InfoPageType.CALIBRATION)} />
          <Calibration channel={channel} />
        </>
      )}
    </>
  );
};

export default GateConfiguration;
