import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { Checkbox, Header } from '../../../../../../components';
import { useDevicesAndChannelsContext } from '../../../../../../hooks/devices-and-channels/provider';
import { useOptimizerConfigurationContext } from '../../../context/configuration-context';
import { InputSliderDialog } from '../input-slider-dialog';

interface ComponentProps {
  tempMinOption?: boolean;
  minTemperatureExists?: boolean;
}

export const OptimizerConfigurationTemperature: React.FC<ComponentProps> = ({
  tempMinOption = false,
  minTemperatureExists = false,
}) => {
  const { t } = useTranslation('channel-details');
  const { control, setValue, watch } = useFormContext();
  const { optimizerConfigValidation } = useDevicesAndChannelsContext();
  const { errors } = useOptimizerConfigurationContext();
  const { tempMin, tempMax, tempMinEnabled, tempSelfConsumption } = watch();

  useEffect(() => {
    if (tempSelfConsumption !== undefined) {
      if (tempSelfConsumption < tempMin) setValue('tempSelfConsumption', tempMin);
      if (tempSelfConsumption > tempMax) setValue('tempSelfConsumption', tempMax);
    }
  }, [tempMin, tempMax]);

  return (
    <>
      <Header title={t('optimizer.configuration.temperature')} />

      {tempMinOption && (
        <Controller
          control={control}
          name={'tempMinEnabled'}
          render={({ field }) => (
            <Checkbox onChange={(e) => field.onChange(e)} checked={field.value} reverse>
              {t('optimizer.configuration.tempMinEnabled')}
            </Checkbox>
          )}
        />
      )}

      <div
        className={c('optimizer-configuration-form__item optimizer-configuration-form__item-row', {
          'full-space': !minTemperatureExists,
        })}
      >
        {minTemperatureExists && (
          <InputSliderDialog
            propertyName="tempMin"
            propertyValue={tempMin}
            unit="°C"
            dialogTitle={t('optimizer.configuration.minTemp.long')}
            inputLabel={t('optimizer.configuration.minTemp.short')}
            disabled={tempMinEnabled === false}
            noBottomMargin
            {...(optimizerConfigValidation ? { min: optimizerConfigValidation.tempMin.min } : {})}
            {...(optimizerConfigValidation ? { max: optimizerConfigValidation.tempMin.max } : {})}
            {...(optimizerConfigValidation?.tempMin.step ? { step: optimizerConfigValidation.tempMin.step } : {})}
            {...(errors.tempMin && { errorMessage: errors.tempMin })}
          />
        )}
        <InputSliderDialog
          propertyName="tempMax"
          propertyValue={tempMax}
          unit="°C"
          maxWidth={!minTemperatureExists}
          dialogTitle={t('optimizer.configuration.maxTemp.long')}
          inputLabel={t('optimizer.configuration.maxTemp.short')}
          noBottomMargin
          {...(optimizerConfigValidation ? { min: optimizerConfigValidation.tempMax.min } : {})}
          {...(optimizerConfigValidation ? { max: optimizerConfigValidation.tempMax.max } : {})}
          {...(optimizerConfigValidation?.tempMax.step ? { step: optimizerConfigValidation.tempMax.step } : {})}
          {...(errors.tempMax && { errorMessage: errors.tempMax })}
        />
      </div>
    </>
  );
};
