import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Api } from 'lavva.exalushome';
import { IWszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/IWszfBidiConfigService';
import { WindAndBrightnessThresholds } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigParams';
import { WszfBidiConfigService } from 'lavva.exalushome.extalife/build/js/Devices/DeviceServices/WindSensor/WszfBidiConfigService';
import { ExtaLifeService } from 'lavva.exalushome.extalife/build/js/ExtaLife';
import { IDevice } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import ArrowButton from '../../../../../../components/arrow-button';
import { useBackdropContext } from '../../../../../../hooks';
import { SensorType } from '../../../../enums';
import { getSensorType } from '../../../../utils';
import { useUpdates } from '../../../controller-settings/hooks/use-updates';
import ConfigCustomPage from '../../../device-details/components/config-custom';
import { useConfigurationService } from '../../../device-details/hooks/use-configuration-service';
import BrightnessThresholdForm from './brightness-threshold';
import { initialConfig, WszBidiConfigParams, WszBidiParamsEnum } from './types';
import WindThresholdForm from './wind-threshold';

interface ComponentProps {
  device: IDevice;
  channel?: IDeviceChannel;
}

const WszfBidiConfigForm: React.FC<ComponentProps> = ({ device, channel }) => {
  const { t } = useTranslation('device-info');
  const [config, setConfig] = useState<WszBidiConfigParams>(initialConfig);
  const [open, setOpen] = useState<boolean>(false);
  const extaLifeService = Api.Get<ExtaLifeService>(ExtaLifeService.ServiceName);
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleGetParamsResponse, DiagnosticButton, DiagnosticResultPopup, firmwareService, UserManualButton } =
    useConfigurationService(device);
  const { VersionDeviceButton, CheckUpdateDeviceButton } = useUpdates(device);

  const getDefaultParams = async (panel: WszBidiParamsEnum) => {
    if (channel) {
      turnOnBackdrop();
      const configService = await extaLifeService.GetDeviceServiceByServiceTypeAsync<IWszfBidiConfigService>(
        WszfBidiConfigService.ServiceName,
      );

      const data = await configService.GetBrightnessAndWindThresholdAsync(device, channel.Number);
      turnOffBackdrop();

      handleGetParamsResponse<WindAndBrightnessThresholds>(data, () => {
        setConfig({
          ...initialConfig,
          [panel]:
            panel === WszBidiParamsEnum.WIND_THRESHOLD
              ? (data as WindAndBrightnessThresholds).WindThreshold
              : (data as WindAndBrightnessThresholds).BrightnessThreshold,
        });
        setOpen(true);
      });
    }
  };

  const channelType = useMemo(() => (channel ? getSensorType(channel) : null), [channel]);

  const handleOpen = (panel: WszBidiParamsEnum) => getDefaultParams(panel);

  const handleBack = () => {
    setOpen(false);
    setConfig({ ...initialConfig });
  };

  const activeConfig = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(config).find(([_, value]) => value !== null);
  }, [config]);

  const renderCorrentForm = () => {
    if (!activeConfig) return;
    const [configName, configParam] = activeConfig;

    switch (configName) {
      case WszBidiParamsEnum.WIND_THRESHOLD: {
        return channel ? <WindThresholdForm device={device} windThreshold={configParam} channel={channel} /> : null;
      }
      case WszBidiParamsEnum.BRIGHTNESS_THRESHOLD: {
        return channel ? (
          <BrightnessThresholdForm device={device} brightnessThreshold={configParam} channel={channel} />
        ) : null;
      }
      default:
        return null;
    }
  };

  return (
    <>
      {open ? (
        <ConfigCustomPage handleBack={handleBack}>{renderCorrentForm()}</ConfigCustomPage>
      ) : (
        <>
          {channel ? (
            <>
              {channelType === SensorType.Wind && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.WszfBidiParams.WindThreshold')}
                  onClick={() => handleOpen(WszBidiParamsEnum.WIND_THRESHOLD)}
                />
              )}
              {channelType === SensorType.MeasuredBrightness && (
                <ArrowButton
                  className="m-b-24"
                  title={t('exalus.params.WszfBidiParams.BrightnessThreshold')}
                  onClick={() => handleOpen(WszBidiParamsEnum.BRIGHTNESS_THRESHOLD)}
                />
              )}
            </>
          ) : (
            <>
              {firmwareService && VersionDeviceButton}
              {firmwareService && CheckUpdateDeviceButton}
              {DiagnosticButton}
              {UserManualButton}
            </>
          )}
        </>
      )}
      {DiagnosticResultPopup}
    </>
  );
};

export default WszfBidiConfigForm;
