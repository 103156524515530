import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, fromUnixTime } from 'date-fns';
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';
import { PHASES_COLORS } from '../../../../../const';
import './index.scss';

interface PropsInterface {
  tooltipProps: TooltipProps<ValueType, NameType>;
  unit: string;
  shouldBeAbs?: boolean;
}

export const LiveTooltip: React.FC<PropsInterface> = ({ tooltipProps, unit, shouldBeAbs }) => {
  const { t } = useTranslation('channel-details');

  if (!tooltipProps?.active || !tooltipProps?.label) {
    return null;
  }

  return (
    <div className="live-tooltip">
      <p className="live-tooltip__label">{format(fromUnixTime(tooltipProps.label), 'HH:mm:ss')}</p>
      {tooltipProps.payload?.[0].payload?.phasesValues?.length ? (
        tooltipProps.payload?.[0].payload?.phasesValues?.map((phaseValue, index) => (
          <p key={index} className="live-tooltip__value">
            <span className="live-tooltip__value-heading" style={{ color: PHASES_COLORS[index + 1] }}>
              {t('phase')} {index + 1}:
            </span>{' '}
            {shouldBeAbs ? Math.abs(phaseValue) : phaseValue} {unit}
          </p>
        ))
      ) : (
        <p className="live-tooltip__value live-tooltip__value--color-accent">
          {tooltipProps.payload?.[0].value} {unit}
        </p>
      )}
    </div>
  );
};
