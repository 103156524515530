import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { availableInstallationIcons, InstallationIcon } from '../../../../components';
import { EditIcon } from '../../../../components/edit-icon';
import {
  ChangeInstallationIconMutation,
  ChangeInstallationIconMutationVariables,
  InstallationModelResponse,
  UserInstallation,
} from '../../../../data-access/gql-types/graphql';
import { CHANGE_INSTALLATION_ICON } from '../../../../data-access/mutations/installations';
import { useBackdropContext, useInstallation } from '../../../../hooks';
import { useMutationErrors } from '../../../../hooks/backend-errors/use-mutation-errors';
import { InstallationIconTypeEnum, MutationErrorType } from '../../../../types';
import { isLocalApp } from '../../../../utils/helpers/local-app';
import { toastError, toastSuccess } from '../../../../utils/toast';
import { CreateInstallationControlForm } from '../../types';

interface ComponentProps {
  updateInstallation?: (key: keyof UserInstallation, value: string | InstallationModelResponse) => void;
}

const InstallationIconEdit: React.FC<ComponentProps> = ({ updateInstallation }) => {
  const { t } = useTranslation('installation');
  const { t: tc } = useTranslation('common');
  const { setValue, watch } = useFormContext<CreateInstallationControlForm>();
  const { selectedInstallationId, selectedInstallation } = useInstallation();
  const { turnOnBackdrop, turnOffBackdrop } = useBackdropContext();
  const { handleErrors } = useMutationErrors(MutationErrorType.Installation);
  const [changeInstallationIcon] = useMutation<ChangeInstallationIconMutation, ChangeInstallationIconMutationVariables>(
    CHANGE_INSTALLATION_ICON,
  );
  const { hex_color, icon_name } = watch();

  const onChangeIcon = useCallback(
    (value: string) => {
      setValue('icon_name', value);

      if (updateInstallation && selectedInstallation?.iconName !== value) {
        if (!isLocalApp) {
          turnOnBackdrop();
          changeInstallationIcon({
            variables: {
              input: {
                installationId: selectedInstallationId,
                iconName: value,
              },
            },
            onCompleted: (data) => {
              turnOffBackdrop();
              if (!data || !data.changeInstallationIcon) {
                toastError({ content: tc('errors.somethingWentWrong') });
                return;
              }

              if (data.changeInstallationIcon.result?.ok) {
                updateInstallation('iconName', value);
                toastSuccess({ content: t('iconEditSuccess') });
              } else {
                handleErrors(data.changeInstallationIcon.errors || []);
              }
            },
            onError: () => turnOffBackdrop(),
          });
        } else {
          updateInstallation('iconName', value);
          toastSuccess({ content: t('iconEditSuccess') });
        }
      }
    },
    [selectedInstallationId, selectedInstallation],
  );

  return (
    <EditIcon
      currentIcon={icon_name}
      iconList={availableInstallationIcons}
      handleChangeIcon={onChangeIcon}
      installationIcon={<InstallationIcon iconName={InstallationIconTypeEnum[icon_name]} activeColor={hex_color} />}
      iconBackgroundColor={hex_color}
      header={t('editIconHeader')}
    />
  );
};

export default InstallationIconEdit;
