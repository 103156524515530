import { ReactNode } from 'react';
import { DeviceResponseType } from 'lavva.exalushome/build/js/Services/Devices/IDevice';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { BlindActionEnum, FacadeActionEnum } from 'lavva.exalushome/build/js/Services/Devices/Tasks/Tasks';
import { LockType, NotifyType } from 'lavva.exalushome/build/js/Services/Scenes/SceneTaskTypes';
import { SceneTaskType } from 'lavva.exalushome/build/js/Services/Scenes/Scenes';
import { SupportedArgumentTypes, SupportedTaskTypes } from 'lavva.exalushome/build/js/Services/Scenes/ScenesBuilder';
import { GateActionInternal } from '../../../../action-create/types';
import { ChannelType } from '../../../enums';

export interface ActionChecked {
  deviceGuid: string;
  title: string;
  icon: ReactNode;
  checked: boolean;
}

export enum ConditionType {
  Undefined = 'undefined',
  Devices = 'devices',
  Schedule = 'schedule',
  Location = 'location',
  Scenarios = 'scenarios',
}

export enum TaskVariant {
  Undefined = 'undefined',
  OnOff = 'onOff',
  Dim = 'dim',
  SetMicrowave = 'setMicrowave',
  SetRGBW = 'setRGBW',
  SetBlind = 'setBlind',
  SetFacade = 'setFacade',
  SetGate = 'SetGate',
}

export enum TriggerMethodType {
  IfIsIt = 'ifIsIt',
  WhenChangeOn = 'whenChangeOn',
}

export enum ConditionSourceType {
  Undefined = 'undefined',
  Remotes = 'remotes',
  BlindRemotes = 'blindRemotes',
  FacadeRemotes = 'facadeRemotes',
  Bells = 'bells',
  Temperature = 'temperature',
  Humidity = 'humidity',
  WindSpeed = 'windSpeed',
  WindThreshold = 'windThreshold',
  Brightness = 'brightness',
  Pressure = 'pressure',
  FloodSensor = 'floodSensor',
  Energy = 'energy',
  ReedSensor = 'reedSensor',
  MovementSensor = 'movementSensor',
  Gate = 'gate',
  Time = 'time',
  DaysOfWeek = 'daysOfWeek',
  Timer = 'timer',
  Astronomical = 'astronomical',
  ScenarioExecution = 'scenarioExecution',
}

export interface DaysOfWeekOption {
  label: string;
  value: number;
  checked: boolean;
}

export enum DaysPeriod {
  Work = 'work',
  Weekend = 'weekend',
}

export interface ConditionDeviceSourceOption {
  title: string;
  icon: ReactNode;
  sourceType: ConditionSourceType;
  deviceResponses: DeviceResponseType[];
}

export interface ConditionSourceOption {
  title: string;
  icon: ReactNode;
  sourceType: ConditionSourceType;
  allowType: SupportedArgumentTypes;
}

export interface ConditionTypeOption {
  title: string;
  content: string;
  icon: ReactNode;
  type: ConditionType;
}

export interface ActionTaskVariantOption {
  title: string;
  icon: ReactNode;
  variant: TaskVariant;
  types: ChannelType[];
}

export interface ActionTaskOption {
  title: string;
  icon: ReactNode;
  type: SceneTaskType;
}

export interface OnOffStateTask {
  isOn: boolean;
}

export interface DimStateTask {
  brightness: number;
}

export interface MicroventilationStateTask {
  position: number;
}

export interface RGBWStateTask {
  R: number;
  G: number;
  B: number;
  W?: number;
  Brightness: number;
}

export interface BlindStateTask<T> {
  position: number | null;
  action: T;
}

export interface GateStateTask {
  position: number | null;
  action: GateActionInternal;
}

export interface FacadeStateTask {
  position: number;
  tilt: number;
  action: FacadeActionEnum;
}

export interface DeviceTaskParams {
  channel: IDeviceChannel;
  state: TaskState;
  variant: TaskVariant;
}

export interface DelayTaskParams {
  delay: string;
}

export interface LockTaskParams {
  lockType: LockType;
  resetLockScenesGuids: string[];
  lockStart: string;
  lockEnd?: string;
}

export interface NotifyTaskParams {
  message: string;
  notifyType: NotifyType;
  notificationClients: string[];
}

export type TaskState =
  | OnOffStateTask
  | DimStateTask
  | MicroventilationStateTask
  | RGBWStateTask
  | BlindStateTask<BlindActionEnum>
  | FacadeStateTask;

export type TaskParams = DeviceTaskParams | DelayTaskParams | LockTaskParams | NotifyTaskParams;

export interface Task {
  id: string;
  taskType: SceneTaskType;
  taskParams: TaskParams;
}

export interface TimeItem {
  hour: number;
  height: number;
}

export enum BrightnessMethod {
  Percent = 'percent',
  Number = 'number',
  State = 'state',
}

export enum CreateTaskValidateMethod {
  Ignore = 'IGNORE',
  TryFix = 'TRY_FIX',
}

export const supportedTaskTypeBySceneTaskType = {
  [SceneTaskType.DelayTask]: SupportedTaskTypes.DelayTask,
  [SceneTaskType.DeviceTask]: SupportedTaskTypes.DeviceTask,
  [SceneTaskType.LockExecution]: SupportedTaskTypes.LockExecutionTask,
  [SceneTaskType.NotifyTask]: SupportedTaskTypes.NotifyTaskAppNotify,
};

export interface ChannelNotifyAvailable {
  channel: IDeviceChannel;
  type: DeviceResponseType;
  measurementType?: string;
}

export interface MessageByType {
  key: string;
  unit: string;
  more?: Record<string, { key: string; unit: string }>;
}
