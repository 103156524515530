import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IChannelsGroup } from 'lavva.exalushome/build/js/Services/Devices/IChannelsGroupsService';
import { IDeviceChannel } from 'lavva.exalushome/build/js/Services/Devices/IDeviceChannel';
import { groupBy } from 'lodash';
import * as uuid from 'uuid';
import { CustomBackdrop, EmptyStateBox, IconWarning } from '../../../../../../components';
import { ROUTES } from '../../../../../../routes';
import { CentralActionBox } from '../../../../components/central-action/box';
import { useExalusStorageContext } from '../../../../context/storage';
import { ChannelType } from '../../../../enums';
import { getChannelType } from '../../../../utils';
import { ChannelsSortBody } from '../../../group-sort-channels/components/body';
import { useDashboardChannels } from '../../hooks/use-dashboard-channels';
import { useSortedChannels } from '../../hooks/use-sorted-channels';
import { CentralActionType } from '../../types';

interface PropsInterface {
  group: IChannelsGroup;
  isFetched: boolean;
  groupChannels?: IDeviceChannel[];
  isDraggable?: boolean;
}

export const ExalusGroup: React.FC<PropsInterface> = ({ groupChannels, group, isFetched, isDraggable }) => {
  const { t: tg } = useTranslation('groups');
  const { t: tc } = useTranslation('common');
  const { isDashboardGridView } = useExalusStorageContext();
  const { groupChannelsItems } = useDashboardChannels({ groupChannels, isDraggable });
  const { sortableItems, setSortableItems } = useSortedChannels({ groupChannels, isDraggable, group });

  const groupedChannelsGroupedByType = useMemo(
    () => groupBy(groupChannels || [], (channel) => getChannelType(channel)),
    [groupChannels],
  );

  const groupCentralActions = useMemo(() => {
    const centrals: CentralActionType[] = [];

    if (groupedChannelsGroupedByType) {
      Object.keys(groupedChannelsGroupedByType).forEach((type) => {
        const groupedChannelsGrouped = groupedChannelsGroupedByType[type];
        if (type === ChannelType.Switch) {
          (groupChannels || [])
            .filter((channel) => getChannelType(channel) === ChannelType.Light)
            .forEach((lightChannel) => {
              groupedChannelsGrouped.push(lightChannel);
            });
        }
        if (groupedChannelsGrouped.length >= 2) {
          if (
            type === ChannelType.Blind ||
            type === ChannelType.Switch ||
            type === ChannelType.Facade ||
            type === ChannelType.Light
          ) {
            centrals.push({
              id: uuid.v4(),
              items: groupedChannelsGrouped,
              type,
              name: tc(`typesPlural.exalus.channel.${type}`),
              groupId: group.Guid,
            });
          }
        }
      });
    }
    return centrals;
  }, [groupedChannelsGroupedByType, groupChannels, tc]);

  if (!groupChannels) return null;

  return (
    <>
      <div
        className={classNames('p-b-24 dashboard__device-list', {
          'grid grid--2 grid--small-gap': isDashboardGridView && groupChannels?.length > 0,
          'grid-list-16': !isDashboardGridView && groupChannels?.length > 0,
        })}
      >
        {isFetched ? (
          groupChannels.length > 0 ? (
            <>
              {groupCentralActions?.map((centralAction) => {
                return (
                  <CentralActionBox
                    key={centralAction.id}
                    isListItem={!isDashboardGridView}
                    centralAction={centralAction}
                    isDraggable={isDraggable}
                  />
                );
              })}
              {!isDraggable ? (
                groupChannelsItems()
              ) : (
                <ChannelsSortBody sortableChannels={sortableItems} setSortableChannels={setSortableItems} />
              )}
            </>
          ) : (
            <EmptyStateBox
              header={tg('custom.header')}
              btnText={tg('custom.add')}
              content={tg('custom.emptyState')}
              icon={<IconWarning />}
              linkTo={ROUTES.GroupEdit(group.Guid)}
            />
          )
        ) : (
          <CustomBackdrop loading />
        )}
      </div>
    </>
  );
};
